/* got this from https://codepen.io/CSWApps/pen/YyerKb and will build off it */

.main-container,
.preview-container {
  border: 2px solid;
  border-bottom-color: #535353;
  border-right-color: #535353;
  border-left-color: #dbdbdb;
  border-top-color: #dbdbdb;
  background-color: #bfbfbf;
  font-size: 13px;
  padding: 2px;
  ul.toolbar {
    border-bottom: 1px solid #808080;
    list-style: none;
    padding: 2px 0;
    margin: 0;
    li {
      display: inline-block;
      padding: 0 5px;
    }
  }
  .container-inner {
    border: 2px solid;
    border-bottom-color: #c7c7c7;
    border-right-color: #c7c7c7;
    border-left-color: #808080;
    border-top-color: #808080;
    background-color: #fff;
    padding: 10px;
    ul.icons {
      list-style: none;
      padding: 2px 0;
      margin: 0;
      li {
        display: inline-block;
        padding: 0 5px;
      }
    }
  }
  .statusbar {
    .left, .right {
      border: 1px solid;
      border-color: #888 #dadada #dadada #888;
      display: inline-block;
      padding: 0px 5px;
      font-size: 11px;
    }
    .left {
      width: 33%;
    }
    .right {
      width: 66%;
    }
  }
}
.title {
  background-color: #5E5E5E;
  padding: 2px 4px 3px 4px;
  position: relative;
  h1 {
    font-weight: bold;
    color: #fff;
    font-size: 13px;
    margin: 0;
  }
}

.mock-link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

/* button styles from https://codepen.io/wiledal/pen/ngJsy */
$gray: #fdbbde;
$gray-dark: #5c2686;
$gray-light: white;
$blue: #000080;

@mixin borders {
  background: $gray !important;
  border-top: 1px solid $gray-light !important;
  border-left: 1px solid $gray-light !important;
  border-right: 1px solid $gray-dark !important;
  border-bottom: 1px solid $gray-dark !important;
  border-radius: 0px  !important;
  box-shadow: inset -1px -1px 0 0 $gray-dark, inset 1px 1px 0 0px #fff !important;
  padding: 2px !important;
}

@mixin inset-border {
  border-top: 1px solid $gray-dark !important;
  border-left: 1px solid $gray-dark !important;
  
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
}

button,
select {
  @include borders;
  
  display: inline-block !important;
  color: black  !important;
  box-shadow: inset -1px -1px 0 0 $gray-dark, inset 1px 1px 0 0px $gray-light !important;
  border-top: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  font-family: 'w95' !important;
  font-size: 18px;
  padding: 0 20px 0 20px !important;
  height: 50px !important;
  
  &:active {
    //background: $gray-dark;
    
    box-shadow: inset 1px 1px 0 0 #000, inset -1px -1px 0 0px $gray-light !important;
    border-bottom: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    border-top: 1px solid $gray-dark !important;
    border-left: 1px solid $gray-dark !important;
  
  }
}

/* override the ugly file uploader styling */ 
.fileContainer {
  background: none !important;
  box-shadow: none !important;
}

/* address bar attempt */
.ie-bar {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-top: 1px solid #fff;
  padding: 2px 0;

  ::before,
  ::after {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  .label {
    padding: 0 4px;
  }
  .label::before, .label::after {
    content: '';
    position: absolute;
  }
  .combo {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1;
    border-width: 1px;
    border-style: solid;
    border-color: #808080 #fff #fff #808080;
    margin-right: 2px;

    input {
      -webkit-box-flex: 1;
      flex: 1;
      border: none;
      outline: none;
      background: #fff;
      color: #000;
      padding: 4px;
      line-height: 12px;
    }

    .dropdown {
      float: right;
      width: 18px;
      margin: 1px 1px 1px 0;
      position: relative;
      background: #c0c0c0;
      border-width: 1px;
      border-style: solid;
      border-color: #c0c0c0 #000 #000 #c0c0c0;
    }
    .dropdown::before {
      content: '';
      position: absolute;
      left: calc(50% - 4px);
      top: calc(50% - 2px);
      border-style: solid;
      border-width: 4px 4px 0;
      border-color: #000 transparent;
    }
    .dropdown::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: #fff #808080 #808080 #fff;
      pointer-events: none;
    }
  }
  .combo::after {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #000 #c0c0c0 #c0c0c0 #000;
    pointer-events: none;
  }
}