@import './assets/windows95';

@font-face {
  font-family: 'w95';
  src: url('./assets/w95fa-webfont.woff2') format('woff2'),
       url('./assets/w95fa-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

html {
  box-sizing: border-box;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline: none;
}

body {
  background: rgb(0,0,0);
  background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(92,38,134,1) 35%, rgba(255,22,144,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(92,38,134,1) 35%, rgba(255,22,144,1) 100%);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(92,38,134,1) 35%, rgba(255,22,144,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ff1690",GradientType=1);
  font-family: 'w95';
  height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
}


/* temp css maybe*/ 

img.photoPreview, 
canvas.decorateCanvas,
video {
  width: 100%;
}

.main-container {
  width: 800px;
  margin: 10px 0;
}

.main-container {
  max-width: 100%;
}

img.decoration {
  width: 100px;
}

.main-container.secondary.window {
  max-width: 400px;
}

div#decoration-container {
  margin-top:10px;
  height: 300px;
  overflow: scroll;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, .75fr);
  align-items: center;
}

button.btn.remove {
  font-size: 12px;
  height: 25px !important;
  padding: 0 10px !important;
}

.webcamWrapper {
  display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 15px;
grid-row-gap: 0px;
}

.webcam-main {
  text-align: center;
}

.canvas-container {
  margin: 0 auto;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 320px) { 
  .wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 10px;
  }
  img.purrybooth-logo {
    display: block;
    width: 100px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  button.btn.webcamBtn {
    visibility: hidden;
  }
  .main-container.secondary.window {
    width: 100%;
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 10px;
  }
  .main-container {
    margin-right: 10px;
}
button.btn.webcamBtn {
  visibility: visible;
}
img.purrybooth-logo {
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 10px;
}
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
  }
  .main-container {
    margin-right: 10px;
}
button.btn.webcamBtn {
  visibility: visible;
}
img.purrybooth-logo {
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 10px;
}
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  .wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
  }
  .main-container {
    margin-right: 10px;
}
button.btn.webcamBtn {
  visibility: visible;
}
img.purrybooth-logo {
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 10px;
}
 }

 .toolbar.z-index {
  width: 150px;
}

.toolbar.remove {
  width: 100px;
}

.toolbar {
  margin: 0 auto;
  display: block;
  cursor: pointer;
  padding: 10px;
}

p {
  font-size: 16px;
}

.continue {
  text-align: center;
}

.mode-page .continue button {
  margin: 0 10px;
}

.modal.wrapper {
  position: absolute;
  padding-top: 50px;
  z-index: 10000000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

.modal p {
  color: black;
}

.main-container.modal {
  margin: 0 auto;
}

.gh a {
  text-decoration: none !important;
  color: black;
}

img.photo-splash {
  width: 100%;
}